import "./Hero.css";

const Hero = (props) => {
  return (
    <>
      <div className={props.cName}>
        <img alt="Hero image" src={props.heroImg} />
      </div>

      <div className="hero-text">
        <h1 className={props.heading}>{props.title}</h1>
        <p>{props.text}</p>
      </div>
    </>
  );
};

export default Hero;
