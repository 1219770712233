import React, { useRef, useState } from "react";
import "./ContactForm.css";
import emailjs from "@emailjs/browser";

const ContactForm = () => {

  return (
    <div className="form-container">
      <h1>Get in touch with us</h1>
      <p>Tel: +27 63 359 9366</p>
      <p>Email: info@risaservices.co.za</p>
    </div>
  );
};

export default ContactForm;
