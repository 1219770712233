export const NavbarItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "nav-links",
  },
];
