import "./Descriptor.css";
import img1 from "../assets/Calculator.jpeg";
import img2 from "../assets/Sheets.jpeg";

const Descriptor = () => {
  return (
    <div className="descriptor">
      <h1>Who We Are</h1>

      <div className="first-desc">
        <div className="descriptor-text">
          <p>
            Risa Services formed out of a passion for Accounting, all aspects of
            business and a desire to provide the best fit for client and fulfil
            all their requirements from implementation, recruiting and then
            maintaining their records.
          </p>
          <p>
            Our deep appreciation for each client’s unique needs means we work
            as partners with our clients. We are specialized in full
            Bookkeeping, payroll Services, Secretarial, Recruitment & training
            with extensive experience in various industries.
          </p>
          <p>
            100% black female ownership - Level 1 BBBEEE rating and a
            procurement recognition of 135%.
          </p>
        </div>
        <div className="descriptor-img">
          <img alt="placeholder" src={img1} />
          <img alt="placeholder" src={img2} />
        </div>
      </div>
    </div>
  );
};

export default Descriptor;
