import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import HeroImg from "../assets/Phone.jpeg";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        cName="hero-mid"
        heroImg={HeroImg}
        title="Contact"
        heading="heading-mid"
      />
      <ContactForm />
      <Footer />
    </>
  );
};

export default Contact;
