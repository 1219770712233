import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../assets/Logo.png";

const Footer = () => {
  return (
    <div className="footer">
      {/* <div className="top-row">
        
      </div> */}

      <div className="bottom-row">
        {/* <div>
          <h4>Address</h4>
          <p>Street</p>
          <p>City</p>
          <p>Postal Code</p>
        </div> */}
        <div>
          <img alt="placeholder" src={logo} />
        </div>

        <div>
          <h4>Navigation</h4>
          <Link className="url" to="/">
            Home
          </Link>
          <Link className="url" to="/about">
            About
          </Link>
          <Link className="url" to="/contact">
            Contact
          </Link>
        </div>
        <div>
          <h4>Contact</h4>
          <p>Tel: +27 63 359 9366</p>
          <p>Email: info@risaservices.co.za</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
