import "./Services.css";

const Services = () => {
  return (
    <div className="services">
      <h1>Our Services</h1>

      <div className="first-service">
        <h3>Start-up and Secretarial Services</h3>
        <div className="service-text">
          <ul>
            <li>
              Company Registration with B-BEE certificates and Tax Clearance
            </li>
            <li>Domain and Websites</li>
            <li>Annual Returns (CIPC)</li>
            <li>Company & Director amendments</li>
            <li>CSD Registration Completion</li>
            <li>Assistance & Compilation of Tenders</li>
          </ul>
        </div>
      </div>
      <div className="second-service">
        <h3>Bookkeeping and Accounting Services</h3>
        <div className="service-text">
          <ul>
            <li>
              Full Bookkeeping / Accounting Services up to management Accounts
            </li>
            <li>Budgets and Cashflows</li>
            <li>Completion and Submission of VAT</li>
            <li>PAYE and Income Tax</li>
            <li>Annual Financial Statements</li>
          </ul>
        </div>
      </div>
      <div className="third-service">
        <h3>Outsourced and Payroll Services</h3>
        <div className="service-text">
          <ul>
            <li>Monthly/Weekly Reports and Payslips</li>
            <li>Preparing CSV files for upload</li>
            <li>
              Monthly and annually statutory submissions, (EMP201, EMP501)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Services;
