import "./AboutContainer.css";

const About = () => {
  return (
    <div className="about-container">
      <h1>Our Mission</h1>
      <p>
        We strive to provide personal and specialized services to meet each
        client’s individual needs.
      </p>
      <p>
        Our mission drives us to continually demonstrate reliability, integrity,
        dedication and to support our clients through their challenges &
        Requirements.
      </p>

      <h1>Our Vision</h1>
      <p>
        Our vision is to become the preferred service provider for all our
        client’s needs.
      </p>
      <p>
        We understand the need for proficient and dependable partners which is a
        mission, this is why we are fully committed to providing invaluable
        service that contributes to a company’s success.
      </p>
      <p>
        At Risa Services, we embrace and uphold the highest standards of
        personal and professional ethics, honesty and integrity.
      </p>
      <p>
        We strive to share knowledge for continuous improvement, learning and
        innovation.
      </p>

      <h1>Our Team</h1>
      <p>
        Risa Services is a team of experts with extensive experience in all
        fields of services we provide. Our team offers excellence to ensure that
        we provide the best outcome.
      </p>
    </div>
  );
};

export default About;
