import { useEffect } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Descriptor from "../components/Descriptor";
import Services from "../components/Services";
// import Cards from "../components/Cards";
import Footer from "../components/Footer";
import HeroImg from "../assets/Office.jpeg";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />
      <Hero
        cName="hero"
        heroImg={HeroImg}
        title="Welcome"
        heading="heading"
      />
      <Descriptor />
      {/* <Cards /> */}
      <Services />
      <Footer />
    </>
  );
};

export default Home;
